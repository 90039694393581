<template>
  <div class="inte-exch-record">
    <!-- start 条件过滤区 -->
    <div class="df-row">
      <!-- 日期选择器 -->
      <div class="df-col">
        <el-date-picker
          v-model="multipleDate"
          type="daterange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          size="mini"
          value-format="yyyy-MM-dd"
          @change="onChangeDate"
        >
        </el-date-picker>
      </div>

      <!-- 物品状态选择器 -->
      <div class="df-col">
        <el-select v-model="submitData.state" placeholder="请选择" size="mini">
          <el-option
            v-for="item in stateList"
            :key="item.Value"
            :label="item.Name"
            :value="item.Value"
          >
          </el-option>
        </el-select>
      </div>

      <!-- 查询文本框 -->
      <div class="df-col">
        <el-input v-model="submitData.word" size="mini" />
      </div>

      <!-- 查询按钮 -->
      <div class="df-col">
        <el-button type="primary" size="mini" @click="onSearchData">
          查询
        </el-button>
      </div>

      <!-- 添加按钮 -->
      <div class="df-col">
        <el-button type="primary" size="mini" @click="onAddGoods">
          新增物品
        </el-button>
      </div>

      <!-- 自动生成物品按钮 -->
      <div class="df-col">
        <el-button type="primary" size="mini" @click="onAutoAddGoods">
          自动生成积分消费物品
        </el-button>
      </div>

      <!-- 生成导出物品按钮 -->
      <div class="df-col">
        <el-button type="primary" size="mini" @click="onExportGoods">
          生成积分导出物品
        </el-button>
      </div>
    </div>
    <!-- end 条件过滤区 -->

    <!-- start 积分兑换记录 -->
    <el-table
      :data="tableData"
      :max-height="tableMaxHeight"
      border
      stripe
      v-loading="loading"
    >
      <el-table-column label="物品图片" width="120">
        <template slot-scope="scope">
          <el-image :src="scope.row.GoodsImage"></el-image>
        </template>
      </el-table-column>
      <el-table-column prop="GoodsNo" label="物品编号"></el-table-column>
      <el-table-column prop="GoodsName" label="物品名称"></el-table-column>
      <el-table-column prop="GoodsPrice" label="物品单价"></el-table-column>
      <el-table-column prop="UnitsName" label="物品单位"></el-table-column>
      <el-table-column prop="GoodsNote" label="备注"></el-table-column>
      <el-table-column prop="GoodsStateName" label="物品状态"></el-table-column>
      <el-table-column label="更新时间">
        <template slot-scope="scope">
          {{
            util.dateFormat(
              "YYYY-mm-dd HH:MM",
              new Date(scope.row.UpdateTime * 1000)
            )
          }}
        </template>
      </el-table-column>
      <el-table-column label="操作" width="320">
        <template slot-scope="scope">
          <div v-if="scope.row.GoodsState == 1">
            <el-button size="mini" @click="onSettingGoods(scope.row)"
              >设置</el-button
            >

            <el-button
              type="primary"
              size="mini"
              @click="onEditGoods(scope.row)"
              >修改</el-button
            >

            <el-button
              type="warning"
              size="mini"
              :disabled="stopDisabled"
              @click="onDelAndResGoods(scope.row, 2)"
              >停用</el-button
            >

            <el-button
              type="danger"
              size="mini"
              :disabled="deleteDisabled"
              @click="onDelAndResGoods(scope.row, 3)"
              >删除</el-button
            >
          </div>

          <div v-if="scope.row.GoodsState > 1">
            <el-button
              type="warning"
              size="mini"
              :disabled="recoverDisabled"
              @click="onDelAndResGoods(scope.row, 1)"
              >恢复</el-button
            >
          </div>
        </template>
      </el-table-column>
    </el-table>
    <!-- end 积分兑换记录 -->

    <!-- start 分页器 -->
    <el-pagination
      background
      :page-sizes="[5, 10, 15, 20]"
      :page-size="submitData.page_size"
      :current-page="submitData.page_index"
      layout="total, sizes, prev, pager, next, jumper"
      :total="totalRecord"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    >
    </el-pagination>
    <!--end 分页器-->

    <AddExchDialog ref="AddExchDialog" @success="onSearchData"></AddExchDialog>

    <SettingGoodsRulesDialog
      ref="SettingGoodsRulesDialog"
    ></SettingGoodsRulesDialog>
  </div>
</template>

<script>
import Integral from "@/api/integral.js";
import AddExchDialog from "./components/add-goods-dialog.vue";
import SettingGoodsRulesDialog from "./components/setting-goods-rules-dialog.vue";

export default {
  components: { AddExchDialog, SettingGoodsRulesDialog },

  data() {
    return {
      formData: {},

      submitData: {
        // 提交数据
        begin_date: "",
        end_date: "",
        word: "", // 关键词
        state: 0, // 状态
        page_index: 1, // 页码
        page_size: 10, // 页数
      },
      multipleDate: [],
      stateList: [],
      tableData: [], // 积分物品数据列表
      totalRecord: 0, // 分页器总页数
      tableMaxHeight: "", // 表格最大高度
      loading: false, // 表格加载
      stopDisabled: false,
      deleteDisabled: false,
      recoverDisabled: false,
    };
  },

  computed: {
    // 时间格式化函数
    dateFormat() {
      return function(fmt, timestamp) {
        return this.util.dateFormat(fmt, new Date(timestamp * 1000));
      };
    },
  },

  created() {
    this.getResize();
  },

  mounted() {
    window.addEventListener("resize", this.getResize);
    this.multipleDate = [
      this.util.getNowMonthToday(),
      this.util.getNowMonthToday(),
    ];
    this.onChangeDate();

    this.initPageData();
    this.getGoodsMiantListPage();
  },

  destroyed() {
    window.removeEventListener("resize", this.getResize);
  },

  methods: {
    // 获取浏览器窗口大小
    getResize() {
      let { clientHeight } = this.util.getDocumentElementInfo();
      this.tableMaxHeight = clientHeight - (100 + 68 + 32);
    },

    // 初始化表格数据
    async initPageData() {
      try {
        let { data } = await Integral.initGoodsMaintList();
        this.stateList = data.stateList;
      } catch (err) {
        console.log(err);
      }
    },

    // 获取兑换物品数据（分页）
    async getGoodsMiantListPage() {
      this.loading = true;
      try {
        let { data } = await Integral.getGoodsMiantList(this.submitData);
        this.totalRecord = data.filter.TotalRecord;
        this.tableData = data.list;
      } catch (err) {
        console.log(err);
      } finally {
        this.loading = false;
      }
    },

    // 查询按钮点击事件
    onSearchData() {
      this.submitData.page_index = 1;
      this.getGoodsMiantListPage();
    },

    // 打开新增物品对话框
    onAddGoods() {
      this.$refs.AddExchDialog.onShowDialog({GoodsImage: ''});
    },

    // 自动生成一个积分消费物品
    async onAutoAddGoods() {
      try {
        let { errcode } = await Integral.createInteExpeGoods();
        if (errcode == 0) {
          this.$message.success("积分消费物品生成成功");
          this.onSearchData();
        }
      } catch (err) {
        console.log(err);
      }
    },

    // 生成积分导出物品
    async onExportGoods() {
      try {
        let { errcode } = await Integral.createInteExportGoods();
        if (errcode == 0) {
          this.$message.success("积分导出物品生成成功");
          this.onSearchData();
        }
      } catch (err) {
        console.log(err);
      }
    },

    // 打开设置物品规则对话框
    onSettingGoods(event) {
      this.$refs.SettingGoodsRulesDialog.onShowDialog(event);
    },

    // 打开修改物品信息对话框
    onEditGoods(event) {
      this.$refs.AddExchDialog.onShowDialog(event);
    },

    // 停用、删除、恢复物品
    async onDelAndResGoods(event, state) {
      try {
        let submitData = {
          goods_guid: event.GoodsGuid,
        };
        let res, text;
        let callback;
        if (state == (await 1)) {
          this.recoverDisabled = true;
          callback = async () => {
            res = await Integral.restoreGoods(submitData);
          };

          text = "恢复";
        } else if (state == 2) {
          this.stopDisabled = true;
          callback = async () => {
            res = await Integral.disableGoods(submitData);
          };

          text = "停用";
        } else if (state == 3) {
          this.deleteDisabled = true;
          callback = async () => {
            res = await Integral.deleteGoods(submitData);
          };

          text = "删除";
        }

        this.$confirm(`确定要${text}物品 【${event.GoodsName}】 吗？`, "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(async () => {
            await callback();

            await this.$nextTick(() => {
              if (res.errcode == 0) {
                this.$message.success(
                  `物品 【${event.GoodsName}】 ${text}成功`
                );
                this.onSearchData();
              }
            });
          })
          .finally(() => {
            this.recoverDisabled = false;
            this.stopDisabled = false;
            this.deleteDisabled = false;
          });
      } catch (err) {
        console.log(err);
      }
    },

    // 时间更改事件
    onChangeDate() {
      this.submitData.begin_date =
        this.multipleDate.length > 0
          ? this.multipleDate[0]
          : this.util.getNowMonthToday();
      this.submitData.end_date =
        this.multipleDate.length > 0
          ? this.multipleDate[1]
          : this.util.getNowMonthToday();
    },

    // 分页：每页显示多少条数据
    handleSizeChange(event) {
      this.submitData.page_index = 1;
      this.submitData.page_size = event;
      this.getGoodsMiantListPage();
    },

    // 分页：跳转到第几页
    handleCurrentChange(event) {
      this.submitData.page_index = event;
      this.getGoodsMiantListPage();
    },
  },
};
</script>

<style lang="less" scoped>
.inte-exch-record {
  .df-row{
    margin-top: 20px;
  }
}

.el-image {
  width: 80px;
  height: 80px;
  text-align: center;
  line-height: 56px;
  font-weight: bold;
  font-size: 20px;
  border-radius: 5px;
  vertical-align: middle;
  background-color: #c8c5c8;
}

.el-pagination {
  margin-top: 20px;
  text-align: right;
}
</style>
