<template>
  <div class="setting-goods-rules-dialog">
    <el-dialog
      title="积分兑换物品规则设置"
      top="10vh"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <!-- start 顶部tab栏 -->
      <div class="top-box">
        <div class="seach-box">
          <!-- 分类下拉框 -->
          <el-select
            size="small"
            v-model="natureNo"
            placeholder="全部"
          >
            <el-option
              v-for="item in natureList"
              :key="item.NatureNo"
              :label="item.NatureName"
              :value="item.NatureNo"
            >
            </el-option>
          </el-select>

          <!-- 搜索输入框 -->
          <el-input size="small" v-model="word"></el-input>

          <!-- 查找按钮 -->
          <el-button type="primary" size="small" @click="onSeachData"
            >查找</el-button
          >
        </div>

        <div class="edit-box">
          <!-- 编辑模式按钮 -->
          <el-button
            type="primary"
            size="small"
            @click="editMode = !editMode"
            >{{ editMode ? "关闭编辑" : "开启编辑" }}</el-button
          >
        </div>
      </div>
      <!-- end 顶部tab栏 -->

      <!-- start 项目分类设置的table模板 -->
      <el-table v-loading="loading" height="560" :data="tableData">
        <el-table-column prop="CardNo" label="编号"></el-table-column>
        <el-table-column prop="CardName" label="卡类名称"></el-table-column>
        <el-table-column prop="NatureName" label="属性"> </el-table-column>
        <el-table-column label="消费金额">
          <template slot-scope="scope">
            <div v-if="editMode">
              <el-input
                type="number"
                v-model="scope.row.ExchMoney"
                @change="onInputChange(scope.$index)"
                size="small"
                v-focus
                placeholder=""
              />
            </div>
            <div v-else>{{ scope.row.ExchMoney || "无" }}</div>
          </template>
        </el-table-column>
        <el-table-column label="消费积分">
          <template slot-scope="scope">
            <div v-if="editMode">
              <el-input
                type="number"
                v-model="scope.row.ExchInte"
                @change="onInputChange(scope.$index)"
                size="small"
                v-focus
                placeholder=""
              />
            </div>
            <div v-else>{{ scope.row.ExchInte || "无" }}</div>
          </template>
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button
              type="danger"
              size="mini"
              :disabled="!scope.row.RuleGuid || deleteDisabled"
              @click="onDelete(scope.row)"
            >
              移除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- end 项目分类设置的table模板  -->

      <div class="footer-box" slot="footer">
        <!-- start 分页器 -->
        <el-pagination
          background
          :page-sizes="[12, 20, 25]"
          :page-size="pageSize"
          :current-page="pageIndex"
          layout="total, sizes, prev, pager, next, jumper"
          :total="totalRecord"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        >
        </el-pagination>
        <!--end 分页器-->

        <div class="btn-box">
          <el-button size="mini" @click="onCloseDialog">
            取 消
          </el-button>
          <el-button
            ref="submitDisabled"
            size="mini"
            type="primary"
            @click="onSubmitData"
          >
            保 存
          </el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import Integral from "@/api/integral.js";

export default {
  data() {
    return {
      pageIndex: 1,
      pageSize: 20,
      totalRecord: 0,
      natureNo: '',
      word: '',
      submitData: {
        // 添加、修改提交表格
        GoodsGuid: "",
        EditList: [],
      },
      natureList: [],
      tableData: [], // 表格数据列表
      copeTableData: [], // 表格数据拷贝列表
      rules: {},
      loading: false,
      dialogVisible: false,
      editMode: false,
      deleteDisabled: false,
    };
  },

  methods: {
    // 初始化数据
    async initData() {
      try {
        let { data } = await Integral.initRuleEditList();
        this.natureList = data.natureList;
      } catch (err) {
        console.log(err);
      }
    },

    // 获取兑换规则
    async getRuleEditList() {
      this.loading = true;
      try {
        let submitData = {
          goods_guid: this.submitData.GoodsGuid,
          nature_no: this.natureNo,
          word: this.word
        }
        let { data } = await Integral.getRuleEditList(submitData);
        this.copeTableData = data;
        this.totalRecord = data.length;
        this.falsePage();
      } catch (err) {
        console.log(err);
      } finally {
        this.loading = false;
      }
    },

    // 伪分页
    falsePage() {
      let start = (this.pageIndex - 1) * this.pageSize,
        end = this.pageIndex * this.pageSize;
      this.tableData = this.copeTableData.slice(start, end);
      // console.log(this.tableData);
    },

    onSeachData() {
      this.pageIndex = 1;
      this.getRuleEditList();
    },

    onSubmitData() {
      let params = this.copeTableData;
      params.filter(async (item) => {
        if (item.Modified) {
          let param = {
            ...item,
            ExchInte: Number(item.ExchInte),
            ExchMoney: parseFloat(parseFloat(item.ExchMoney).toFixed(2))
          };

          this.submitData.EditList.push(param);
        }
      });

      let modifiedCount = this.submitData.EditList.length;
      // console.log(this.inteEditFromList.EditList);
      if (modifiedCount) {
        this.$confirm(
          `当前已有 ${modifiedCount} 行被修改，您确定将其保存吗？`,
          "保存提示",
          {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          }
        )
          .then(async () => {
            this.submitDisabled = true;
            try {
              let { errcode, errmsg } = await Integral.saveRuleEditList(this.submitData);;

              if (errcode == 0) {
                this.$message.success("保存成功");
                // this.resetData();
                this.getRuleEditList()
              }
            } finally {
              this.submitDisabled = false;
            }
          })
          .finally(() => {
            this.submitData = {
              GoodsGuid: this.submitData.GoodsGuid,
              EditList: []
            }
            this.submitDisabled = false;
          });
      } else {
        this.onCloseDialog();
      }
    },

    // 移除当前列数据事件
    onDelete(event) {
      this.$confirm(`是否删除该项目`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(async () => {
        this.deleteDisable = true;

        try {
          let { errcode, errmsg } = await Integral.deleteRule({rule_guid: event.RuleGuid});

          await this.$nextTick(() => {
            if (errcode == 0) {
              this.$message.success("删除成功");
              this.resetData();
              this.getRuleEditList();
            } else {
              this.$message.error(errmsg);
            }
          });
        } finally {
          this.deleteDisable = false;
        }
      });
    },

    resetData() {
      this.submitData = {
        GoodsGuid: this.submitData.GoodsGuid,
        EditList: []
      };
      this.editMode = false;
      this.deleteDisabled = false;
    },

    // input变化事件
    onInputChange(index) {
      this.tableData[index].Modified = 1;
    },

    // 记录被修改的表格数据
    recordModifiedTableData() {
      let params = this.tableData.filter((item) => {
        return item.Modified;
      });
      params.forEach((item2) => {
        this.copeTableData.filter((item3) => {
          item3.ItemGuid == item2.ItemGuid ? (item3 = item2) : null;
        });
      });
    },

    // 分页：每页显示多少条数据
    handleSizeChange(event) {
      this.pageIndex = 1;
      this.pageSize = event;
      this.falsePage();
    },

    // 分页：跳转到第几页
    handleCurrentChange(event) {
      this.pageIndex = event;
      this.recordModifiedTableData();
      this.falsePage();
    },

    async onShowDialog(itemData) {
      this.submitData = {
        GoodsGuid: itemData.GoodsGuid,
        EditList: []
      };
      this.dialogVisible = true;
      await this.initData();
      await this.getRuleEditList();
    },

    onCloseDialog() {
      this.resetData();
      this.dialogVisible = false;
    },
  },
};
</script>

<style lang="less" scoped>
.setting-goods-rules-dialog {
  .top-box {
    height: 40px;
    .flex-row();
    justify-content: space-between;

    .seach-box {
      flex: 1;
      .flex-row();
      > .el-select {
        width: 170px;
      }

      > .el-input {
        width: 50%;
      }

      > * {
        margin: 0 5px;
      }
    }

    .edit-box {
      flex: 1.5;
      .flex-row();
      justify-content: flex-end;
    }
  }

  .footer-box {
    .flex-row();
    justify-content: space-between;
    .el-pagination {
    }
  }
}
</style>
