<template>
  <div class="add-goods-dialog">
    <el-dialog
      :title="title"
      width="40%"
      :visible.sync="dialogVisible"
      :show-close="false"
      :destroy-on-close="true"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      @close="onCloseDialog"
    >
      <el-form ref="form" :model="submitData" :rules="rules" label-width="80px">
        <div class="upload-box">
          <DFUpload
            ref="DFUpload"
            @readyUpload="onReadyUpload"
            @success="onUploadSuccess"
            @error="onUploadError"
            @remove="onRemove"
          >
          </DFUpload>
        </div>

        <div class="info-box">
          <div class="df-row">
            <div class="df-col">
              <el-form-item label="物品编号" prop="GoodsNo">
                <el-input v-model="submitData.GoodsNo"></el-input>
              </el-form-item>
            </div>

            <div class="df-col">
              <el-form-item label="物品名称" prop="GoodsName">
                <el-input v-model="submitData.GoodsName"></el-input>
              </el-form-item>
            </div>
          </div>

          <div class="df-row">
            <div class="df-col">
              <el-form-item label="物品单价" prop="GoodsPrice">
                <el-input v-model="submitData.GoodsPrice"></el-input>
              </el-form-item>
            </div>

            <div class="df-col">
              <el-form-item label="物品单位" prop="UnitsGuid">
                <el-select v-model="submitData.UnitsGuid" placeholder="请选择">
                  <el-option
                    v-for="item in unitsList"
                    :key="item.UnitsGuid"
                    :label="item.UnitsName"
                    :value="item.UnitsGuid"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </div>
          </div>

          <div class="df-row">
            <div class="df-col">
              <el-form-item label="来源项目">
                <span v-show="submitData.ItemGuid" style="margin-inline-end: 10px;">{{ submitData.ItemName }}</span>
                <el-button v-show="submitData.ItemGuid" type="danger" size="mini" plain @click="onClearItem">清除</el-button>
                <el-button
                  type="primary"
                  size="small"
                  @click="onShowGoods"
                  >选择项目</el-button
                >
              </el-form-item>
            </div>
          </div>

          <div class="df-row">
            <div class="df-col">
              <el-form-item label="物品备注">
                <el-input
                  type="textarea"
                  v-model="submitData.GoodsNote"
                  :autosize="{ minRows: 4, maxRows: 10 }"
                ></el-input>
              </el-form-item>
            </div>
          </div>
        </div>
      </el-form>

      <div slot="footer">
        <el-button size="mini" @click="onCloseDialog">
          取 消
        </el-button>
        <el-button
          size="mini"
          type="primary"
          :disabled="submitDiabled"
          @click="onSubmitData"
        >
          保 存
        </el-button>
      </div>
    </el-dialog>

    <!-- 选择项目对话框 -->
    <DfGoodsDialog
      ref="DfGoodsDialog"
      :multiple="false"
      @onConfirm="onSelectItems"
    ></DfGoodsDialog>
  </div>
</template>

<script>
import Integral from "@/api/integral.js";
import { baseURL } from "@/utils/axios.js";

export default {
  data() {
    return {
      title: "新增物品",
      rowGutter: 20,
      colSpan: 11,
      submitData: {
        GoodsGuid: "",
        GoodsNo: "",
        GoodsName: "",
        GoodsPrice: 0,
        UnitsGuid: "",
        GoodsNote: "",
        GoodsImage: "",
        GoodsImageUrl: "",
        ItemGuid: "",
        ItemName: "",
      },
      rules: {
        GoodsNo: [
          { required: true, message: "请输入物品编号", trigger: "blur" },
        ],
        GoodsName: [
          { required: true, message: "请输入物品名称", trigger: "blur" },
        ],
        GoodsPrice: [
          { required: true, message: "请输入物品单价", trigger: "blur" },
        ],
        UnitsGuid: [
          { required: true, message: "请选择物品单位", trigger: "blur" },
        ],
      },
      unitsList: [],
      dialogVisible: false,
      submitDiabled: false,
    };
  },

  watch: {
    submitData: {
      deep: true,
      handler(newVal) {
        let fileList = this.$refs.DFUpload ? this.$refs.DFUpload.fileList : [];
        if (
          fileList.length != 0 &&
          fileList[0].url == this.submitData.GoodsImageUrl
        ) {
          return;
        }

        let pictureArray = this.submitData.GoodsImageUrl
          ? [{ url: this.submitData.GoodsImageUrl }]
          : [];

        this.$nextTick(() => {
          // this.$refs.DFUpload.initImageList(pictureArray)
          pictureArray ? this.$refs.DFUpload.initImageList(pictureArray) : null;
        });
      },
    },
  },

  methods: {
    // 初始化数据
    async initData(params) {
      try {
        let submitData = undefined;
        if (params.GoodsGuid) {
          submitData = {
            goods_guid: params.GoodsGuid,
          };
        }
        let { data } = await Integral.initSaveGoods(submitData);
        this.unitsList = data.unitsList;
        this.submitData = data.editGoods.GoodsGuid
          ? data.editGoods
          : { GoodsImage: "", GoodsImageUrl: "" };
      } catch (err) {
        console.log(err);
      }
    },

    /* 提交积分商品信息
    图片上传组件回调，由于组件要做到通用封装，逻辑十分复杂，修改代码切记
    先处理图片上传的返回地址，然后再提交给接口 */
    async onUploadSuccess(event) {
      let imageUrl = event ? event.data : "";
      let submitData = JSON.parse(JSON.stringify(this.submitData));
      submitData.GoodsImage = imageUrl ? imageUrl : submitData.GoodsImage;
      try {
        // 如果有 GoodsGuid 就是修改
        if (submitData.GoodsGuid) {
          let { errcode } = await Integral.saveGoods(submitData);
          if (errcode == 0) {
            this.$message.success(`物品 【${submitData.GoodsName}】 修改成功`);
            this.resetData();
            this.$emit("success");
            this.onCloseDialog();
          }
          this.submitDiabled = false;
        } else {
          let { errcode } = await Integral.saveGoods(submitData);
          if (errcode == 0) {
            this.$confirm(
              `物品 【${submitData.GoodsName}】 添加成功，需要继续添加新物品吗？`,
              "提示",
              {
                confirmButtonText: "继续",
                cancelButtonText: "取消",
                type: "success",
              }
            )
              .catch(() => {
                this.onCloseDialog();
              })
              .finally(() => {
                this.resetData();
                this.$emit("success");
                console.log(this.submitData);
              });
          }
          this.submitDiabled = false;
        }
      } catch (err) {
        console.log(err);
        this.submitDiabled = false;
      }
    },

    // 图片删除回调
    async onRemove(file) {
      this.submitData.GoodsImage = "";
      this.submitData.GoodsImageUrl = "";
      this.$refs.DFUpload.initImageList([]);
    },

    // 确定按钮点击事件
    async onSubmitData() {
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          let submitData = this;
          let token = await window.sessionStorage.getItem("token");

          if (typeof parseFloat(submitData.GoodsPrice) !== "number") {
            return this.$message.info("物品单价必须为小数或整数");
          }
          submitData.GoodsPrice = parseFloat(
            parseFloat(submitData.GoodsPrice).toFixed(2)
          );

          try {
            let actionUrl = (await this.submitData.GoodsImage)
              ? ""
              : baseURL + `/integral/upload_goods_image?acctoken=${token}`;
            console.log(actionUrl);
            await this.$refs.DFUpload.submitUpload(actionUrl);
          } catch (e) {
            console.log(e);
            this.submitDiabled = await false;
          }
        } else {
          return false;
        }
      });
    },

    // 打开消费项目、会员卡对话框
    onShowGoods(event) {
      this.$refs.DfGoodsDialog.onShowDialog();
    },

    // 选择项目回调
    onSelectItems(event) {
      let submitData = JSON.parse(JSON.stringify(this.submitData));
      submitData.ItemGuid = event.selectItems[0].ItemGuid;
      submitData.ItemName = event.selectItems[0].ItemName;
      this.submitData = submitData;
    },

    // 清除项目
    onClearItem(){
      let submitData = JSON.parse(JSON.stringify(this.submitData));
      submitData.ItemGuid = "";
      submitData.ItemName = "";
      this.submitData = submitData;
    },

    // 添加图片到组件回显队列
    onReadyUpload(event) {
      this.submitData.GoodsImageUrl = event.url;
    },

    // 图片上传失败回调
    onUploadError(event) {
      console.log(event);
    },

    // 重置数据
    resetData() {
      this.submitData = {};
    },

    // 打开对话款
    onShowDialog(itemData = {}) {
      let params = JSON.parse(JSON.stringify(itemData)) || {};
      this.title = itemData.GoodsGuid ? "修改物品" : "新增物品";
      this.dialogVisible = true;
      this.initData(params);
    },

    // 关闭对话框
    onCloseDialog() {
      this.resetData();
      this.$refs["form"].resetFields();
      this.dialogVisible = false;
    },
  },
};
</script>

<style lang="less" scoped>
.add-goods-dialog {
  .upload-box {
    margin-bottom: 20px;
  }

  .df-col {
    flex: 1;
  }

  .el-select {
    width: 100%;
  }
}
</style>
